<template>
  <div class="iotProductForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <div class="iotDeviceForm-body">
        <div class="iotDeviceForm-t">
          <v-steps :stepData="stepData" :active="active"></v-steps>
        </div>
        <div class="iotDeviceForm-b">
          <div class="iotDeviceForm-b-l" v-if="active == 0">
            <el-form-item
              label="选择园区"
              :rules="[
                { required: true, message: '请选择园区', trigger: 'blur' },
              ]"
              prop="spaceId"
            >
              <community-select
                v-model="form.spaceId"
                specifyCollectionTypes=""
                spaceTypes="10,30"
              />
            </el-form-item>
            <el-form-item
              label="名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="name"
            >
              <v-input
                placeholder="请输入名称"
                v-model="form.name"
                :width="250"
                :maxlength="300"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="分类"
              :rules="[
                { required: true, message: '请选择分类', trigger: 'change' },
              ]"
              prop="category"
            >
              <v-select
                clearable
                filterable
                :options="kindList"
                v-model="form.category"
              />
            </el-form-item>
            <el-form-item
              label="节点类型"
              :rules="[
                {
                  required: true,
                  message: '请选择节点类型',
                  trigger: 'change',
                },
              ]"
              prop="nodeType"
            >
              <v-select
                clearable
                filterable
                :options="nodeTypeList"
                v-model="form.nodeType"
              />
            </el-form-item>

            <el-form-item
              label="入网方式"
              :rules="[
                {
                  required: true,
                  message: '请选择入网方式',
                  trigger: 'change',
                },
              ]"
              prop="netType"
            >
              <v-select
                clearable
                filterable
                :options="netTypeList"
                v-model="form.netType"
              />
            </el-form-item>

            <el-form-item
              label="认证类型"
              :rules="[
                {
                  required: true,
                  message: '请选择认证类型',
                  trigger: 'change',
                },
              ]"
              prop="authType"
            >
              <v-select
                clearable
                filterable
                :options="authTypeList"
                v-model="form.authType"
              />
            </el-form-item>
          </div>
          <div class="iotDeviceForm-b-r" v-if="active == 1">
            <div class="modal">
              <div
                class="areaTimes"
                v-for="(item, index) in modalList"
                :key="index"
              >
                <div class="areaTimes-l">
                  <div class="item">
                    <div class="item-l">属性名称</div>
                    <div class="item-r">
                      <v-input
                        placeholder="请输入属性名称"
                        v-model="item.name"
                        :width="250"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">属性变量名</div>
                    <div class="item-r">
                      <v-input
                        placeholder="请输入属性变量名"
                        v-model="item.identifier"
                        :width="250"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">属性类型</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="valueTypeList"
                        v-model="item.dataSpecs.type"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">单位</div>
                    <div class="item-r">
                      <v-input
                        placeholder="请输入单位"
                        v-model="item.dataSpecs.specs.unitName"
                        :width="250"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">取值范围</div>
                    <div class="item-r">
                      <v-input
                        placeholder="初始值"
                        v-model="item.dataSpecs.specs.min"
                        :width="100"
                        type="number"
                        min="0"
                      ></v-input>
                      <span class="range">-</span>
                      <v-input
                        placeholder="结束值"
                        v-model="item.dataSpecs.specs.max"
                        :width="100"
                        min="0"
                        type="number"
                      ></v-input>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">是否必填</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="isFill"
                        v-model="item.required"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-l">属性权限</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="valueAuth"
                        v-model="item.rwFlag"
                      />
                    </div>
                  </div>
                </div>
                <div class="areaTimes-r">
                  <div class="opera">
                    <v-button
                      text="删除"
                      v-if="index != 0"
                      @click="outRoom(item, index)"
                      type="warning"
                    ></v-button>
                    <v-button
                      v-if="index == modalList.length - 1"
                      text="新增"
                      @click="addRoom"
                    ></v-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="iotDeviceForm-b-r" v-if="active == 2">
            <div class="modal">
              <div class="areaTimes" v-for="(v, index) in events" :key="index">
                <div class="areaTimes-l">
                  <div class="item">
                    <div class="item-l">事件绑定</div>
                    <div class="item-r">
                      <v-select
                        clearable
                        filterable
                        :options="eventContentLIst"
                        @change="handelEventBinding($event, v)"
                        v-model="v.issueCode"
                      />
                    </div>
                  </div>
                  <div
                    class="event-prop"
                    v-for="(item, idx) in v.attr"
                    :key="idx"
                  >
                    <div class="event-prop-l">
                      <div class="item">
                        <div class="item-l">属性名称</div>
                        <div class="item-r">
                          <v-input
                            placeholder="请输入属性名称"
                            v-model="item.name"
                            :width="250"
                          ></v-input>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item-l">属性变量名</div>
                        <div class="item-r">
                          <v-input
                            placeholder="请输入属性变量名"
                            v-model="item.identifier"
                            :width="250"
                          ></v-input>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item-l">属性类型</div>
                        <div class="item-r">
                          <v-select
                            clearable
                            filterable
                            :options="valueTypeList"
                            v-model="item.dataSpecs.type"
                          />
                        </div>
                      </div>
                      <div class="item">
                        <div class="item-l">单位</div>
                        <div class="item-r">
                          <v-input
                            placeholder="请输入单位"
                            v-model="item.dataSpecs.specs.unitName"
                            :width="250"
                          ></v-input>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item-l">取值范围</div>
                        <div class="item-r">
                          <v-input
                            placeholder="初始值"
                            v-model="item.dataSpecs.specs.min"
                            :width="100"
                            type="number"
                            min="0"
                          ></v-input>
                          <span class="range">-</span>
                          <v-input
                            placeholder="结束值"
                            v-model="item.dataSpecs.specs.max"
                            :width="100"
                            min="0"
                            type="number"
                          ></v-input>
                        </div>
                      </div>
                      <div class="item">
                        <div class="item-l">是否必填</div>
                        <div class="item-r">
                          <v-select
                            clearable
                            filterable
                            :options="isFill"
                            v-model="item.required"
                          />
                        </div>
                      </div>
                      <div class="item">
                        <div class="item-l">属性权限</div>
                        <div class="item-r">
                          <v-select
                            clearable
                            filterable
                            :options="valueAuth"
                            v-model="item.rwFlag"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="event-prop-r">
                      <div class="opera">
                        <v-button
                          text="删除属性"
                          v-if="idx != 0"
                          @click="removeProp(v, idx)"
                          type="warning"
                        ></v-button>
                        <v-button
                          v-if="idx == v.attr.length - 1"
                          text="新增属性"
                          @click="addProp(v)"
                        ></v-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="areaTimes-r">
                  <div class="opera">
                    <v-button
                      text="删除事件"
                      v-if="index != 0"
                      @click="removeEvent(index)"
                      type="warning"
                    ></v-button>
                    <v-button
                      v-if="index == events.length - 1"
                      text="新增事件"
                      @click="addEvent()"
                    ></v-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #saveBeforeSlot
        ><v-button
          text="上一步"
          @click="toBefore"
          v-if="active == 1 || active == 2"
        >
        </v-button>
        <v-button
          text="下一步"
          @click="toAfter"
          v-if="active == 0 || active == 1"
        >
        </v-button>
        <v-button text="保存" @click="toAfter" v-if="active == 2"> </v-button>
      </template>
    </form-panel>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>

<script>
import {
  getProInfoUrl,
  addProList,
  getDeviceCredentials,
  getDeviceProList,
  getDeviceInfoUrl,
  proAllListUrl,
  valueListUrl,
  saveModelUrl,
  saveEventlUrl,
} from "./api.js";
import {
  valueTypeListMap,
  valueTypeList,
  isFillMap,
  isFill,
  valueAuth,
  warnTypeMap,
  warnType,
  valueAuthMap,
  noticeWay,
  noticeWayMap,
} from "./map.js";

export default {
  name: "placeEdit",
  data() {
    return {
      warnType,
      noticeWay,
      valueAuth,
      isFill,
      valueTypeList,
      warnTypeSelect: [],
      markSelect: [],
      width: 250,
      active: 0,
      stepData: [
        { title: "产品详细信息", key: 1 },
        { title: "模型信息", key: 2 },
        { title: "事件信息", key: 3 },
      ],
      submitConfig: {
        queryUrl: "",
        submitUrl: "",
      },
      kindList: [],
      modalList: [
        {
          name: "",
          rwFlag: "",
          createTs: new Date().getTime(),
          required: true,
          dataSpecs: {
            type: "",
            specs: {
              max: "",
              min: "",
              unit: "",
              unitName: "",
            },
          },
          identifier: "",
        },
      ],
      nodeTypeList: [],
      netTypeList: [],
      authTypeList: [],
      showMapDialog: false,
      isBefore: false,
      isAfter: true,
      deviceCredentials: "",
      productId: "",
      events: [
        {
          attr: [
            {
              name: "",
              rwFlag: "",
              createTs: new Date().getTime(),
              required: true,
              dataSpecs: {
                type: "",
                specs: {
                  max: "",
                  min: "",
                  unit: "",
                  unitName: "",
                },
              },
              identifier: "",
            },
          ],
          issueCode: "",
          issueName: "",
        },
      ],
      form: {
        spaceId: "",
        eventConfig: [],
        attrConfig: [],
        id: "",
        name: "",
        category: "",
        nodeType: "",
        netType: "",
        authType: "",
      },
      eventContentLIst: [], //事件内容数据
    };
  },
  watch: {},
  components: {},
  mounted() {
    this.getProAllListUrl("pro_category");
    this.getProAllListUrl("auth");
    this.getProAllListUrl("net");
    this.getProAllListUrl("node");
    this.getProAllListUrl("operator");
    this.getProAllListUrl("issue_type");
    const { id } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.submitConfig.queryUrl = getProInfoUrl({ deviceId: id });
      this.$refs.formPanel.getData({ id: id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    // 选择事件绑定下拉事件
    handelEventBinding(value, item) {
      const result = this.eventContentLIst.filter(
        (item) => item.value === value
      );
      item.issueName = result[0].label;
    },

    selectWarnTypeKey(item) {
      console.log(item, 990);
      item.expression =
        (item.warnTypeKey ? item.warnTypeKey : "") +
        (item.markSelectKey ? item.markSelectKey : "") +
        (item.warnTypeValue ? item.warnTypeValue : "");
      this.$forceUpdate();
    },
    selectMarkSelectKey(item) {
      item.expression =
        (item.warnTypeKey ? item.warnTypeKey : "") +
        (item.markSelectKey ? item.markSelectKey : "") +
        (item.warnTypeValue ? item.warnTypeValue : "");
      this.$forceUpdate();
    },
    selectInput(item) {
      item.expression =
        (item.warnTypeKey ? item.warnTypeKey : "") +
        (item.markSelectKey ? item.markSelectKey : "") +
        (item.warnTypeValue ? item.warnTypeValue : "");
      this.$forceUpdate();
    },
    // 添加事件绑定
    addEvent() {
      this.events.push({
        attr: [
          {
            name: "",
            rwFlag: "",
            createTs: new Date().getTime(),
            required: true,
            dataSpecs: {
              type: "",
              specs: {
                max: "",
                min: "",
                unit: "",
                unitName: "",
              },
            },
            identifier: "",
          },
        ],
        issueCode: "",
        issueName: "",
      });
    },
    // 删除事件判定
    removeEvent(index) {
      this.events.splice(index, 1);
    },
    // 删除属性
    removeProp(item, index) {
      item.attr.splice(index, 1);
    },
    // 添加属性
    addProp(item) {
      item.attr.push({
        name: "",
        rwFlag: "",
        createTs: new Date().getTime(),
        required: true,
        dataSpecs: {
          type: "",
          specs: {
            max: "",
            min: "",
            unit: "",
            unitName: "",
          },
        },
        identifier: "",
      });
    },
    outRoom(item, index) {
      this.modalList.splice(index, 1);
    },
    addRoom() {
      this.modalList.push({
        name: "",
        rwFlag: "",
        createTs: new Date().getTime(),
        required: true,
        dataSpecs: {
          type: "",
          specs: {
            max: "",
            min: "",
            unit: "",
            unitName: "",
          },
        },
        identifier: "",
      });
    },
    async getValueList() {
      let params = {
        productId: this.productId,
      };
      let res = await this.$axios.get(`${valueListUrl}`, { params });
      if (res.code === 200) {
        res.data.forEach((ele) => {
          let obj = {};
          obj.label = ele.name;
          obj.value = ele.identifier;
          this.warnTypeSelect.push(obj);
        });
      } else {
        return false;
      }
    },
    async saveEvent() {
      let params = {
        productId: this.productId,
        eventConfig: this.events,
      };
      let res = await this.$axios.post(`${saveEventlUrl}`, params);
      if (res.code === 200) {
        this.$message.success("操作成功");
      } else {
        return false;
      }
    },
    async saveProModel() {
      let params = {
        productId: this.productId,
        properties: this.modalList,
      };
      let res = await this.$axios.post(`${saveModelUrl}`, params);
      if (res.code === 200) {
        this.$message.success("操作成功");
      } else {
        return false;
      }
    },
    async savePro() {
      let params = {
        authType: this.form.authType,
        category: this.form.category,
        // subCategory: this.form.subCategory,
        createUser: this.$store.state.app.userInfo.id,
        description: "",
        id: this.form.id,
        isDeleted: 0,
        name: this.form.name,
        netType: this.form.netType,
        nodeType: this.form.nodeType,
        spaceId: this.form.spaceId,
        status: 0,
        updateTime: "",
        updateUser: 0,
      };
      if (this.form.subCategory) {
        params.subCategory = this.form.subCategory;
      }
      let res = await this.$axios.post(`${addProList}`, params);
      if (res.code === 200) {
        this.productId = res.data;
      } else {
        return false;
      }
    },
    async toAfter() {
      if (this.active == 0) {
        await this.savePro();
        this.active = 1;
      } else if (this.active == 1) {
        await this.saveProModel();
        await this.getValueList();
        this.active = 2;
      } else if (this.active == 2) {
        await this.saveEvent();
        this.$router.go(-1);
      }
    },
    toBefore() {
      if (this.active == 0) {
      } else if (this.active == 1) {
        this.active = 0;
      } else if (this.active == 2) {
        this.active = 1;
      }
      this.submitConfig.queryUrl = getProInfoUrl({ deviceId: this.form.id });
      this.$refs.formPanel.getData({ id: this.form.id });
    },
    showMap() {
      this.showMapDialog = true;
    },
    setLngAndLat(l) {
      if (!l.lng || !l.lat) {
        return false;
      }
      this.form.extra.longitude = l.lng;
      this.form.extra.latitude = l.lat;
    },
    async getProAllListUrl(type) {
      let params = {
        code: type,
      };
      this.kindList = [];
      let res = await this.$axios.get(`${proAllListUrl}`, { params });
      if (res.code === 200) {
        if (res.data && res.data[0].children) {
          if (type == "pro_category") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.kindList.push(obj);
            });
          }
          if (type == "node") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.nodeTypeList.push(obj);
            });
          }
          if (type == "net") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.netTypeList.push(obj);
            });
          }
          if (type == "auth") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.authTypeList.push(obj);
            });
          }
          if (type == "operator") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.markSelect.push(obj);
            });
          }
          if (type == "issue_type") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.eventContentLIst.push(obj);
            });
          }
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });

      if (this.form.nodeType != undefined) {
        this.form.nodeType = String(this.form.nodeType);
      }
      if (this.form.attrConfig) {
        this.modalList = JSON.parse(this.form.attrConfig);
        if (this.modalList.length == 0) {
          this.modalList = [
            {
              name: "",
              rwFlag: "",
              createTs: new Date().getTime(),
              required: true,
              dataSpecs: {
                type: "",
                specs: {
                  max: "",
                  min: "",
                  unit: "",
                  unitName: "",
                },
              },
              identifier: "",
            },
          ];
        }
        this.modalList.forEach((ele) => {
          if (!ele.dataSpecs) {
            ele.dataSpecs = {
              type: "",
              specs: {
                max: "",
                min: "",
                unit: "",
                unitName: "",
              },
            };
          }
        });
      }

      if (this.form.eventConfig) {
        this.events = JSON.parse(this.form.eventConfig);
        if (this.events.length == 0) {
          this.events = [
            {
              attr: [
                {
                  name: "",
                  rwFlag: "",
                  createTs: new Date().getTime(),
                  required: true,
                  dataSpecs: {
                    type: "",
                    specs: {
                      max: "",
                      min: "",
                      unit: "",
                      unitName: "",
                    },
                  },
                  identifier: "",
                },
              ],
              issueCode: "",
              issueName: "",
            },
          ];
        }
        this.events.forEach((ele) => {
          if (ele.attr.length == 0) {
            ele.attr = [
              {
                name: "",
                rwFlag: "",
                createTs: new Date().getTime(),
                required: true,
                dataSpecs: {
                  type: "",
                  specs: {
                    max: "",
                    min: "",
                    unit: "",
                    unitName: "",
                  },
                },
                identifier: "",
              },
            ];
          }
          ele.attr.forEach((item) => {
            if (!item.dataSpecs) {
              item.dataSpecs = {
                type: "",
                specs: {
                  max: "",
                  min: "",
                  unit: "",
                  unitName: "",
                },
              };
            }
          });
        });
      }
      this.$forceUpdate();
      console.log(this.modalList, "=+==");
    },
    submitBefore() {
      let target = {};
      Object.assign(target, this.form.extra);
      this.form.extra = JSON.stringify(target);
      return true;
    },
    async getDeviceCredentials(data) {
      let params = {};
      let res = await this.$axios.get(
        `${getDeviceCredentials({ deviceId: data })}`,
        {
          params,
        }
      );
      if (res.code === 200) {
        this.deviceCredentials = res.data;
      }
    },
    submitSuccess(data) {
      console.log(data, "===-----");
      this.active = 1;
      this.isBefore = true;

      this.submitConfig.submitUrl = "";
      this.getDeviceCredentials(data);
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
.iotProductForm {
  box-sizing: border-box;
  height: 100%;
  .iotDeviceForm-body {
    .iotDeviceForm-b {
      padding: 40px;
    }
    .title {
      padding: 10px 40px;
    }
    .label {
      margin: 0 10px;
    }
  }

  .areaTimes {
    display: flex;
    align-items: flex-end;
    padding: 20px 0;
    border-bottom: 1px solid #eee;

    .areaTimes-l {
      margin-right: 20px;
      .event-prop {
        width: 100%;
        display: flex;
        align-items: flex-end;
        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }

        .event-prop-l {
          margin-right: 20px;
        }
        .event-prop-r {
          flex: 1;
          height: 46px;
          /deep/ .v-button {
            margin-right: 10px;
          }
        }
      }
    }
    .areaTimes-r {
      height: 46px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 10px 0;
      .item-l {
        width: 80px;
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
      .item-r {
        display: flex;
        align-items: center;
        .range {
          margin: 0 20px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.iotDeviceForm .v-control {
}
</style>
